<template>
  <v-card class="mx-auto" :disabled="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'servicios',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title +
              (tbl.length > 0 ? ' (' + tbl.length + ')' : '')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            label="Estado"
            v-model="discharged"
            :items="discharged_opts"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            :disabled="tbl.length > 0"
            @change="filterHandle"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            label="Proceso"
            v-model="filter"
            :items="filter_opts"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <DatePicker
            v-if="discharged == 1"
            :label="'F. inicial'"
            :model.sync="start_date"
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <DatePicker
            v-if="discharged == 1"
            :label="'F. final'"
            :model.sync="end_date"
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col v-if="tbl.length == 0" cols="12">
          <v-btn
            block
            small
            color="success"
            @click.prevent="getTbl"
            :loading="ldg"
          >
            Buscar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn block small color="" @click.prevent="tbl = []">
            Cambiar parametros
            <v-icon right> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="tbl_srch"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
            :disabled="tbl.length == 0"
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_hdrs"
            :search="tbl_srch"
            :items="tbl"
            :loading="ldg"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.key="{ item }">
              <b v-text="item.key + 1" />
            </template>
            <template v-slot:item.external="{ item }">
              <v-icon v-if="item.external" small> mdi-close </v-icon>
              <v-icon v-else color="success" small> mdi-check </v-icon>
            </template>
            <template v-slot:item.rs_refound_rejected.created_at="{ item }">
              <div
                v-if="
                  item.rs_refound_rejected &&
                  item.rs_refound_rejected.created_at
                "
              >
                <span
                  :class="
                    item.rs_refound_rejected.rs_refound_rejected_type_id == 2 &&
                    item.rs_refound_rejected.response == 0
                      ? 'orange--text'
                      : ''
                  "
                >
                  {{ item.rs_refound_rejected.created_at }}
                </span>
              </div>
            </template>
            <template v-slot:item.insured.medical_treatment="{ item }">
              <v-icon
                v-if="item.insured && item.insured.medical_treatment"
                small
              >
                mdi-close
              </v-icon>
              <v-icon v-else color="success" small> mdi-check </v-icon>
            </template>
            <template v-slot:item.insured_full_name="{ item }">
              <span
                v-text="item.insured_full_name"
                :class="item.insured ? '' : 'warning--text'"
              />
            </template>
            <template v-slot:item.enrollment="{ item }">
              <span
                v-text="item.enrollment"
                :class="item.insured ? '' : 'warning--text'"
              />
            </template>
            <template v-slot:item.accident="{ item }">
              <v-icon v-if="item.accident == null" color="warning" small>
                mdi-close
              </v-icon>
              <v-icon v-else color="success" small> mdi-check </v-icon>
            </template>
            <template v-slot:item.rs_service.is_revision_approved="{ item }">
              <v-tooltip
                v-if="
                  item.accident != null &&
                  item.rs_service &&
                  item.rs_service.is_revision_approved == 1
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="warning"
                    x-small
                    icon
                    dark
                    class="ml-1"
                  >
                    <v-icon left> mdi-timeline-clock</v-icon>
                    {{ item.rs_service.rs_service_revisions.length }}
                  </v-btn>
                </template>
                <span v-text="'Revisar'" />
              </v-tooltip>
              <v-tooltip
                v-if="
                  item.accident != null &&
                  item.rs_service &&
                  item.rs_service.is_revision_approved == 2
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="error" x-small icon dark class="ml-1">
                    <v-icon left> mdi-timeline-remove </v-icon>
                    {{ item.rs_service.rs_service_revisions.length }}
                  </v-btn>
                </template>
                <span v-text="'Ver revisiones'" />
              </v-tooltip>
              <v-tooltip
                v-if="
                  item.accident != null &&
                  item.rs_service &&
                  item.rs_service.is_revision_approved == 3
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="success"
                    x-small
                    icon
                    dark
                    class="ml-1"
                  >
                    <v-icon left> mdi-timeline-check </v-icon>
                    {{ item.rs_service.rs_service_revisions.length }}
                  </v-btn>
                </template>
                <span v-text="'Ver revisiones'" />
              </v-tooltip>
            </template>
            <template v-slot:item.rs_service.letter_generated="{ item }">
              <div v-if="item.rs_service && item.rs_service.letter_generated">
                <div>{{ item.rs_service.letter_generated }}</div>
                <div>
                  <small>
                    ({{ item.rs_service.letter_generated_by.email }})
                  </small>
                </div>
              </div>
            </template>
            <template v-slot:item.letter_generated="{ item }">
              <v-tooltip
                v-if="item.rs_service && item.rs_service.letter_generated"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="pink darken-2"
                    x-small
                    icon
                    dark
                    @click.prevent="letterView(item)"
                  >
                    <v-icon> mdi-file-certificate </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Ver carta'" />
              </v-tooltip>
              <v-icon v-else small> mdi-close </v-icon>
            </template>
            <template v-slot:item.rs_service.url_survey="{ item }">
              <v-tooltip
                v-if="item.rs_service && item.rs_service.url_survey"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="teal"
                    x-small
                    icon
                    dark
                    @click.prevent="
                      fileDownload(
                        item.rs_service.survey_base64,
                        item.rs_service.survey_ext,
                        'encuesta_serv'
                      )
                    "
                  >
                    <v-icon> mdi-file-eye </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Ver encuesta'" />
              </v-tooltip>
              <v-icon v-else small> mdi-close </v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <div class="text-right">
                <v-tooltip v-if="log.permissions.rss_rs_refounds.update" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :color="item.rs_administratives > 0 ? 'indigo' : 'grey'"
                      x-small
                      icon
                      dark
                      class="ml-1"
                      :to="{
                        name: 'servicios.rs_refounds.administratives',
                        params: { id: item.id },
                      }"
                    >
                      <v-icon> mdi-note-multiple </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Seg. Administrativo'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    log.permissions.rss_rs_refounds.update &&
                    log.role_id != 7 &&
                    log.role_id != 8 &&
                    item.insured_id &&
                    item.rs_refound_bills == false &&
                    item.refound_with_bills &&
                    (!item.rs_service ||
                      item.rs_service.letter_generated_cancelled) &&
                    (!item.rs_refound_rejected ||
                      item.rs_refound_rejected.response)
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="warning"
                      x-small
                      icon
                      dark
                      class="ml-1"
                      :to="{
                        name: 'servicios.rs_refounds.analyze',
                        params: { id: item.id },
                      }"
                    >
                      <v-icon> mdi-account-cash </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Documentación'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    log.permissions.rss_rs_refounds.update &&
                    log.role_id != 7 &&
                    log.role_id != 8 &&
                    item.insured_id &&
                    (item.rs_refound_bills == true ||
                      !item.refound_with_bills) &&
                    (item.accident == null ||
                      (item.rs_service &&
                        item.rs_service.is_revision_approved == 1)) &&
                    (!item.rs_refound_rejected ||
                      item.rs_refound_rejected.response)
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :color="
                        item.rs_service &&
                        item.rs_service.is_revision_approved == 1
                          ? 'grey'
                          : 'pink'
                      "
                      x-small
                      icon
                      dark
                      class="ml-1"
                      :to="{
                        name: 'servicios.rs_refounds.medical_revision',
                        params: { id: item.id },
                      }"
                    >
                      <v-icon> mdi-medical-bag </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Seg. Médico'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    log.permissions.rss_rs_refounds.update && !item.discharged
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="success"
                      x-small
                      icon
                      class="ml-1"
                      @click.prevent="dischargedHandle(item)"
                    >
                      <v-icon> mdi-send-check</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Terminar'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    log.role_id == 1 &&
                    !item.discharged &&
                    item.rs_service_bill &&
                    !item.rs_service_bill.paid_amount
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :color="
                        item.rs_service_bill.block_payment ? 'error' : 'gray'
                      "
                      x-small
                      icon
                      class="ml-1"
                      @click.prevent="blockPaymentHandle(item)"
                    >
                      <v-icon>
                        {{
                          item.rs_service_bill.block_payment
                            ? "mdi-currency-usd-off"
                            : "mdi-currency-usd"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span
                    v-text="
                      item.rs_service_bill.block_payment
                        ? 'Pago bloqueado'
                        : 'Puede pagarse'
                    "
                  />
                </v-tooltip>
                <v-tooltip v-if="log.permissions.rss.read" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="info"
                      x-small
                      icon
                      class="ml-1"
                      :to="{
                        name: 'servicios.rs_refounds.show',
                        params: { rs_id: item.id },
                      }"
                    >
                      <v-icon> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Detalle'" />
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  dateTimeNow,
  rules,
  msgConfirm,
  msgAlert,
  dateTimeToFile,
  base64ToArrayBuffer,
  URL_PDF,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    FaqDlg,
    DatePicker,
    ViewData,
  },
  data() {
    return {
      log: this.$store.getters.getLogin,
      url_pdf: URL_PDF,
      rules: rules(),
      ldg: false,
      tbl: [],
      tbl_srch: "",
      tbl_hdrs: [
        {
          text: "#",
          value: "key",
          filterable: false,
          width: "50",
        },
        {
          text: "Folio",
          value: "folio",
          filterable: true,
        },
        {
          text: "Factura",
          value: "rs_service_bill.id",
          filterable: true,
        },
        {
          text: "F. Creación",
          value: "created_at",
          filterable: true,
        },
        {
          text: "INFO",
          value: "external",
          filterable: true,
        },
        {
          text: "Asegurado",
          value: "insured_full_name",
          filterable: true,
        },
        {
          text: "ID SM",
          value: "enrollment",
          filterable: true,
        },
        {
          text: "Contratante",
          value: "contractor",
          filterable: true,
        },
        {
          text: "Trat. Méd",
          value: "insured.medical_treatment",
          filterable: true,
        },
        {
          text: "Tipo Rechazo",
          value:
            "rs_refound_rejected.rs_refound_rejected_type.rs_refound_rejected_type",
          filterable: true,
        },
        {
          text: "F. Rechazo",
          value: "rs_refound_rejected.created_at",
          filterable: true,
        },
        {
          text: "Seg Méd",
          value: "accident",
          filterable: true,
        },
        {
          text: "Rev",
          value: "rs_service.is_revision_approved",
          filterable: true,
        },
        {
          text: "F. Carta",
          value: "rs_service.letter_generated",
          filterable: true,
        },
        {
          text: "F. Prom Pago",
          value: "rs_service_bill.pay_date",
          filterable: true,
        },
        {
          text: "Carta",
          value: "letter_generated",
          filterable: true,
        },
        {
          text: "Encuesta",
          value: "rs_service.url_survey",
          filterable: true,
        },
        {
          text: "",
          value: "action",
          width: "130",
          sortable: false,
          fixed: true,
        },
      ],
      discharged: 0,
      discharged_opts: [
        { id: 0, name: "PENDIENTES" },
        { id: 1, name: "TERMINADAS" },
      ],
      filter: 0,
      filter_opts: [],
      start_date: null,
      end_date: null,
      item: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    // fileDownload(base64, ext, name) {
    //   const blob = new Blob([base64ToArrayBuffer(base64)], {
    //     type: "application/" + ext,
    //   });
    //   let downloadLink = document.createElement("a");
    //   downloadLink.setAttribute("target", "_blank");
    //   downloadLink.href = URL.createObjectURL(blob);
    //   downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
    //   downloadLink.click();
    // },
    getTbl() {
      this.ldg = true;
      this.tbl = [];

      Axios.get(
        URL_API +
          "/rss/refounds" +
          "?discharged=" +
          this.discharged +
          "&filter=" +
          this.filter +
          "&start_date=" +
          this.start_date +
          "&end_date=" +
          this.end_date,
        headersToken(this.log.token)
      ).then((res) => {
        this.tbl = res.data.data;
        this.ldg = false;
      });
    },
    dischargedHandle(item) {
      this.$swal
        .fire(
          msgConfirm("¿Confirma el terminar el reembolso " + item.folio + "?")
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.ldg = true;

            Axios.post(
              URL_API + "/rss/discharge",
              {
                rs_id: item.id,
                discharged: dateTimeNow(),
              },
              headersToken(this.log.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.getTbl();
              }
            });
          }
        });
    },
    filterHandle() {
      this.filter = 0;

      if (this.discharged == 0) {
        this.filter_opts = [
          {
            id: 1,
            name: "PENDIENTES DE ANALIZAR DOCUMENTACIÓN",
          },
          {
            id: 2,
            name: "PENDIENTES DE SEGUIMIENTO MÉDICO",
          },
          {
            id: 7,
            name: "PENDIENTES DE REVISIÓN",
          },
          {
            id: 3,
            name: "RECHAZADOS POR INFORMACIÓN COMPLEMENTARIA",
          },
          {
            id: 4,
            name: "CON CARTA GENERADA PENDIENTE DE PAGO",
          },
        ];
      } else {
        this.filter_opts = [
          {
            id: 5,
            name: "RECHAZADOS",
          },
          {
            id: 6,
            name: "PROCEDENTES",
          },
        ];
      }

      this.filter_opts.push({
        id: 0,
        name: "TODOS",
      });
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    blockPaymentHandle(item) {
      this.$swal
        .fire(
          msgConfirm(
            "¿Confirma " +
              (item.rs_service_bill.block_payment ? "des" : "") +
              "bloquear el pago del reembolso?"
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.ldg = true;

            Axios.post(
              URL_API + "/rss/services/bills/block/payment",
              {
                rs_service_bill_id: item.rs_service_bill.id,
              },
              headersToken(this.log.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.getTbl();
              }
            });
          }
        });
    },
    letterView(item) {
      window
        .open(this.url_pdf + "/rs/refound/bills/" + item.id, "_blank")
        .focus();
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.log.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    this.filterHandle();

    this.start_date = dateTimeNow().substring(0, 8) + "01";
    this.end_date = dateTimeNow().substring(0, 10);
  },
};
</script>